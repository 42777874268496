.clickableTable {
  cursor: pointer;
}

.tableInputAction {
  width: 30%
}

.height {
  max-height: 300px;
}

.dropDownIcon {
}

.dropdownItem {
}

.select {
  width: 170px;
  margin-left: 5px;
  margin-right: 5px;
}
.placeholder{
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  height: 220px;
  width: 200px;
}

.text{
  margin-left: auto;
  margin-right: auto;
  color: var(--teal);
}

.dropdownColWidth {
 width: 8rem;
}

.infoIcon {
  margin: -1px 10px 0 10px;
  font-size: 20px;
}

.tableOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    flex-grow: 1;
  }
}

.tableInputAction {
  margin-left: 10px;
}
