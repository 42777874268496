.agency_id {
  color: white;
  margin: 0;
  font-weight: 600;
  font-size: 16px;

  @media screen and (max-width: 991px) {
    display: none;
  }
}
