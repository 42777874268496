.react_switch_checkbox {
    height: 10px;
    width: 10px;
    visibility: hidden;
  }
  .react_switch_on {
    background-color: #117fef;
  }
  .react_switch_off {
    background-color: grey;
  } 
  .react_switch_label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50px;
    height: 20px;
    cursor: pointer;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }
  
  .react_switch_label .react_switch_button {
  
    content: none;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .react_switch_checkbox:checked + .react_switch_label .react_switch_button {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
  
  .react_switch_label:active .react_switch_button {
    width: 5px;
  }