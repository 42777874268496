.mob_close_nav {
  display: none;
}
.mob_open_nav {
  display: none;
}

@media screen and (max-width: 576px) {
  .mobile_nav {
    background-color: white !important;
    position: fixed;
    inset: 0;
    z-index: 10;
    animation: goUp 200ms both ease-in;

    .mob_close_nav {
      display: block;
      position: absolute;
      top: 30px;
      right: 20px;
      height: 16px;
    }

    .mobile_nav_brand {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      img {
        width: 50%;
      }
    }

    .mobile_inner {
      opacity: 1;
      display: block;
      position: relative;

      .mobile_nav_links {
        margin: 0;
        .mob_nav_item {
          width: 100%;
          overflow: hidden;
          display: flex;
          justify-content: center;

          a {
            margin-left: -20px;
          }
        }
      }
    }
  }
  .mob_open_nav {
    display: block;
    height: 33px;
    width: 33px;
    z-index: 100;
    top: 22px;
    right: 13px;
    position: absolute;
    border-radius: 50%;
  }
  .hide {
    display: none !important;
  }
  .show {
    display: block;
  }
}

@keyframes goUp {
  from {
    margin-top: -500px;
  }
  to {
    margin-top: 0;
  }
}
